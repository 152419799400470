















































































































































































































































































































































































































































































































































.left-btn {
    position: absolute;
    left: 20px;
    top: 20px;
    z-index: 2;
}
::v-deep .el-dialog.goods-selected-dialog {
    .el-dialog__body {
        padding: 10px 20px;
    }
    .el-dialog__title {
        font-size: 16px;
        color: #060111;
        font-weight: bold;
        font-family: Source Han Sans CN;
        position: relative;
        padding-left: 13px;
        &::before {
            width: 4px;
            height: 12px;
            content: "";
            background-color: #2821fc;
            position: absolute;
            top: 4px;
            left: -9px;
        }
    }
}
.left-container {
    width: 100%;
    height: 100%;
    background-image: linear-gradient(135deg, #12b8c4, #2821fc);
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 17px 1px rgba(11, 12, 28, 0.09);
    border-radius: 17px 0px 0px 20px;
    position: relative;

    .btn-content {
        position: absolute;
        right: 20px;
        top: 20px;
        z-index: 1;
    }

    .left-title {
        line-height: 36px;
        border: 1px solid #fff;
        display: inline-flex;
        margin: 30px auto 10px;
        width: auto;
        font-size: 24px;
        padding: 0 25px;
        position: relative;
        font-weight: bold;
        color: #fff;

        &:before,
        &:after {
            content: "";
            position: absolute;
            top: -1px;
            width: 5px;
            height: 36px;
            border: 1px solid #fff;
            border-radius: 2px;
        }

        &:before {
            left: -7px;
        }

        &:after {
            right: -7px;
        }
    }

    .bg-content {
        position: absolute;
        top: 34px;
        left: 30px;
        width: 554px;
        height: 266px;
        opacity: 0.4;
        background-image: url("../../assets/image/student/bg.png");
    }

    ::v-deep .left-content {
        height: 1%;
        flex: 1;

        & > .el-scrollbar__wrap {
            overflow-x: hidden;
        }
    }

    .left-box {
        padding: 0 20px 20px;
    }
}

.big-title {
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    margin-top: 12px;
    display: flex;
    align-items: center;

    .title-tips {
        line-height: 24px;
        margin-left: 10px;
    }
}

.content-box {
    background: #f0f3fc;
    border-radius: 10px;
    margin-top: 6px;
    color: #342e43;
    padding: 10px;

    .content-title {
        font-weight: bold;
        color: #342e43;
        margin-bottom: 13px;
    }

    .content {
        /*margin-top: 20px;*/
        /*padding: 0 10px;*/
    }
}

.bg-title {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    position: relative;

    .text {
        text-align: center;
        color: #fff;
        padding: 0 37px;
        position: relative;
        font-weight: bold;
        line-height: 24px;
        background: url("../../assets/image/student/title-bg.png") repeat-x;
    }

    .del-content {
        position: absolute;
        right: 0;
        top: 0;
    }

    .left-control {
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        align-items: center;
        cursor: pointer;

        .left-circle {
            width: 18px;
            height: 18px;
            border-radius: 50%;
            background: #2821fc;
            display: flex;
            align-items: center;
            justify-content: center;

            &.is-reverse {
                i {
                    transform: rotateZ(0);
                    transition: transform 0.3s;
                }
            }

            i {
                color: #fff;
                font-size: 8px;
                transform: rotate(-90deg);
                transition: transform 0.3s;
            }
        }

        .left-text {
            margin-left: 10px;
            color: #fff;
        }
    }
}

.line {
    margin-bottom: 8px;

    .num {
        display: inline-block;
        min-width: 20px;
        height: 20px;
        background: #2821fc;
        border-radius: 11px;
        color: #fff;
        text-align: center;
        line-height: 20px;
        font-weight: 500;
        margin-right: 5px;
        padding: 0 4px;
        box-sizing: border-box;
    }

    .detail {
        display: inline;
    }
}

.good-info {
    display: flex;
    align-items: center;

    .good-img {
        width: 62px;
        height: 62px;
        border-radius: 8px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }

    .good-right {
        margin-left: 10px;
        width: 1%;
        flex: 1;
        height: 62px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .good-top {
            color: #1b162a;
            line-height: 18px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
        }

        .right-bottom {
            display: flex;
            font-size: 12px;

            .bottom-item + .bottom-item {
                margin-left: 27px;
            }

            .old-price {
                text-decoration: line-through;
            }

            .red-price {
                color: #ff3e6c;
            }
        }
    }
}

.addGood-box {
    .good-dialog {
        background: #fff;
        /*position: absolute;
        top: 10px;
        left: -10px;
        right: -10px;
        z-index: 9;*/
        border-radius: 4px;

        .dialog-title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 20px;
            font-size: 16px;
            color: #060111;
            font-weight: 500;

            .text {
                position: relative;
                padding-left: 12px;

                &:before {
                    content: "";
                    width: 4px;
                    height: 12px;
                    background: #2821fc;
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }

            .iconfont {
                font-size: 20px;
                cursor: pointer;
                color: #1b162a;
            }
        }

        .add-tips {
            margin: 0 0 10px 0px;
            font-size: 12px;
            color: #1b162a;
        }

        .dialog-content {
            //padding: 0 20px;
        }

        .dialog-bottom {
            padding: 10px 20px;
            border-top: 1px solid #dddfeb;
            text-align: right;
        }
    }
}

.el-table {
    ::v-deep th:first-child > .cell {
        padding-left: 15px;
    }
}
